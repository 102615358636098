import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layouts/layout';
import SEO from '../components/seo';

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>
  </Layout>
);

export default Contact;
